import React from "react";
import Typewriter from "typewriter-effect";

const Type = () => {
  return (
    <Typewriter
      options={{
        strings: [
          "Cybersecurity Solutions & Consulting",
          "Cloud Computing Services & Management",
          "Data Analytics & Business Intelligence",
          "Enterprise Software Development",
          "IT Infrastructure Planning & Implementation",
          "Network Architecture & Optimization",
          "Digital Transformation Strategies",
          "Blockchain Technology Consulting",
          "Artificial Intelligence & Machine Learning Solutions",
          "IT Project Management & Strategy Consulting",
        ],
        autoStart: true,
        loop: true,
        deleteSpeed: 50,
      }}
    />
  );
};

export default Type;
