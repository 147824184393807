import React, { useState } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";

import Particle from "../components/Particle";
import HeroImage from "../assets/hero-image.jpg"; // Replace with your hero image path

const Aboutus = () => {
  const [buttonText, setButtonText] = useState("Learn More");

  const handleButtonClick = () => {
    setButtonText("Coming Soon...");
  };
  return (
    <section className="about-section">
      <Particle />
      <Container>
        <Row className="align-items-center">
          <Col md={6}>
            <div className="mb-4">
              <h1 className="title text-yellow">
                Welcome to VK PRO TECH IT Consulting
              </h1>
              <p className="text-light">
                We started with a vision to bridge the gap between technological
                advancements and business solutions. We are industry veterans
                with a passion for technology, recognized the potential of IT
                solutions in transforming businesses. From our humble beginnings
                in Toronto, we have grown into a multinational organization,
                serving clients across the country.
              </p>
              <p className="text-info">
                Our Mission: To empower businesses through innovative IT
                solutions...
              </p>
            </div>
          </Col>
          <Col md={6} style={{ paddingTop: 10 }}>
            <img src={HeroImage} alt="Hero" className="w-100" />
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Aboutus;
