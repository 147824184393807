import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import LaptopImg from "../../assets/home-main.svg";
import Tilt from "react-parallax-tilt";
import { AiFillGithub, AiOutlineTwitter } from "react-icons/ai";
import { FaLinkedinIn } from "react-icons/fa";

const About = () => {
  return (
    <Container fluid className="home-about-section" id="about">
      <Container>
        <Row>
          <Col md={8} className="home-about-description">
            <h1 style={{ fontSize: "2.6em" }}>
              DISCOVER <span className="purple"> VK PRO TECH INC. </span>
            </h1>
            <p className="home-about-body">
              At <span className="purple">VK Pro Tech Inc.,</span> we specialize
              in harnessing the power of technology to solve complex business
              challenges.
              <br />
              <br />
              With a seasoned team of experts and a track record of delivering
              innovative IT solutions, we are committed to driving the digital
              transformation journeys of our clients.
              <br />
              <br />
              Our expertise spans from{" "}
              <b className="purple"> cloud computing </b> to{" "}
              <b className="purple"> cyber security, </b> and our agile approach
              ensures tailored solutions that not only meet but exceed
              expectations.
              <br />
              <br />
              We pride ourselves on our collaborative process, where we work
              closely with clients to understand their unique needs and vision.
              <br />
              <br />
              Whether it's developing bespoke software or implementing
              cutting-edge infrastructures, VK Pro Tech Inc. is your partner in
              navigating the ever-evolving IT landscape.
              <br />
              <br />
              Join us as we pave the way towards a smarter, more connected
              future.
              <br />
            </p>
          </Col>
          <Col md={4} className="myAvtar">
            <Tilt>
              <img src={LaptopImg} className="img-fluid" alt="avatar" />
            </Tilt>
          </Col>
        </Row>
        <Row>
          <Col md={12} className="home-about-social">
            <h1>FIND US ON</h1>
            <p>
              Please don't hesitate to reach out to us and{" "}
              <span className="yellow">connect.</span>
            </p>
            <ul className="home-about-social-links">
              <li className="social-icons">
                <a
                  href=""
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                  aria-label="github"
                >
                  <AiFillGithub />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href=""
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                  aria-label="twitter"
                >
                  <AiOutlineTwitter />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href=""
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                  aria-label="linkedin"
                >
                  <FaLinkedinIn />
                </a>
              </li>
            </ul>
          </Col>
        </Row>
      </Container>
    </Container>
  );
};

export default About;
