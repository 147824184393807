import React from "react";
import Particle from "../components/Particle";

const Projects = () => {
  return (
    <div style={styles.container}>
      <Particle />
      <h1 style={styles.heading}>Projects</h1>
      <p style={styles.comingSoon}>Coming Soon...</p>
    </div>
  );
};

// Styles
const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    height: "80vh",
    textAlign: "center",
  },
  heading: {
    fontSize: "4rem",
    marginBottom: "2rem",
    color: "#FFFFFF", // white color for the "Projects" heading
  },
  comingSoon: {
    fontSize: "2rem",
    color: "#7500fa", // purple color for the "Coming Soon" text
  },
};

export default Projects;
